import React from "react";
import Slider from "react-slick";
import CurveBottomLeft from "../../assets/images/gray-curve-bottom-left.png";
import CurveRightBottom from "../../assets/images/blue-curve-bottom-2.png";
import CurveBottomLeftGray from "../../assets/images/blue-curve-bottom-gray.png";
import {
  IconWifi,
  IconThumbUpFilled,
  IconWorld,
  IconMessages,
  IconBuildingSkyscraper,
  IconBuildings,
  IconPhoneCall,
  IconMail,
} from "@tabler/icons-react";
import Header from "../../Components/Header";
import { motion } from "framer-motion";
import g2 from "../../assets/images/g2.png";
import a2 from "../../assets/images/a2.png";
import b2 from "../../assets/images/b2.png";

function Services() {
  const ProgressBar = ({ progress, Icon }) => {
    const radius = 48;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;

    return (
      <div className="flex items-center justify-center relative w-full max-w-32">
        <svg viewBox="0 0 100 100" className="w-full">
          <circle
            cx="50"
            cy="50"
            r="48"
            className="stroke-gray-300 stroke-[4] fill-transparent"
          />
          <circle
            cx="50"
            cy="50"
            r="48"
            className="stroke-pink-500 stroke-[4] fill-transparent"
            style={{
              strokeDasharray: circumference,
              strokeDashoffset: offset,
              transition: "stroke-dashoffset 0.3s ease",
            }}
          />
        </svg>
        <div className="absolute flex items-center justify-center w-1/2 h-1/2">
          <Icon className="text-white w-full h-full" />
        </div>
      </div>
    );
  };
  const clients = [
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-estato.png",
      alt: "logo-estato",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-bello.png",
      alt: "logo-bello",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-applauz.png",
      alt: "logo-applauz",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-california.png",
      alt: "logo-hotel-california",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-celeste.png",
      alt: "logo-celeste",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-berg.png",
      alt: "logo-hotel-berg",
    },
  ];
  const settingsclients = {
    lazyLoad: "progressive",
    cssEase: "ease-out",
    speed: 300,
    adaptiveHeight: true,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
    ],
  };

  return (
    <div>
      <Header transparent={false} />
      <section
        className="relative bg-cover bg-no-repeat min-h-screen flex justify-center items-center flex-col"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${a2})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <motion.h1
          className="text-8xl font-bold text-white text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: [0, 1], y: [50, 0] }}
          transition={{ duration: 0.8 }}
          viewport={{ once: false }}
        >
          <img
            src="/logo192.png"
            alt="code"
            height={130}
            width={130}
            className="center ml-[45%]"
          />
          <p className="text-white font-light">Discover Our Expertise in</p>
          <span className="text-pink-500">Digital Transformation</span>
        </motion.h1>
        <motion.p
          className="text-lg font-light text-white mb-4 text-center mt-5 max-w-3xl"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: [0, 1], y: [50, 0] }}
          transition={{ duration: 0.8 }}
          viewport={{ once: false }}
        >
          Appropriately communicate one-to-one technology after plug-and-play
          networks. Quickly aggregate B2B users and worldwide resource-leveling
          potentialities.
        </motion.p>
        <div className="absolute bottom-0 left-0 w-full">
          <img
            src={CurveBottomLeft}
            alt="Top Section Coverage Image"
            className="w-full"
          />
        </div>
      </section>
      <section className="z-0 flex px-40 pt-40 flex-col bg-[#f5f5f5] py-32 justify-center relative">
        <motion.h1
          className="text-5xl md:text-5xl font-extrabold text-pink-500"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: false }}
        >
          Simple Solutions for <br />
          <span className="text-[#210d94]">Complex Challenges</span>
        </motion.h1>
        <div className="py-10 flex flex-row gap-4">
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">Digital Transformation</h1>
            <p className="text-center">
            Modernize your operations with our advanced technologies. We enhance financial processes, automate compliance checks, and facilitate EHR adoption and telemedicine solutions.
            </p>
            <button className="bg-[#210d94] text-white px-4 py-4 ml-2 rounded-full text-base rounded-br-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">Platform Operation</h1>
            <p className="text-center">
            Ensure stability and security of mission-critical platforms. We maintain operational efficiency in FinTech, monitor compliance in GRC, and uphold data integrity in healthcare systems.
            </p>
            <button className="bg-pink-500 text-white px-4 py-4 ml-2 rounded-full text-base rounded-tl-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">Product Engineering</h1>
            <p className="text-center">
            From idea to implementation, we develop secure and scalable financial products, integrate risk management in GRC solutions, and create innovative healthcare devices and software.  
            </p>
            <button className="bg-[#210d94] text-white px-4 py-4 ml-2 rounded-full text-base rounded-br-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">Data Analytics</h1>
            <p className="text-center">
            Gain actionable insights to drive strategic decisions. We specialize in fraud detection for FinTech, risk management for GRC, and predictive modeling for healthcare.
            </p>
            <button className="bg-pink-500 text-white px-4 py-4 ml-2 rounded-full text-base rounded-tl-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
        </div>
        <div className="py-10 flex flex-row gap-4">
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">Cloud Services</h1>
            <p className="text-center">
            Enhance data management with our scalable and secure cloud solutions. We support disaster recovery in FinTech, secure data storage in GRC, and efficient health information management in healthcare.
            </p>
            <button className="bg-[#210d94] text-white px-4 py-4 ml-2 rounded-full text-base rounded-br-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">UX Design</h1>
            <p className="text-center">
            Design intuitive and engaging interfaces for FinTech, GRC, and healthcare applications, focusing on enhanced usability and user experience.
            </p>
            <button className="bg-pink-500 text-white px-4 py-4 ml-2 rounded-full text-base rounded-tl-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">Digital Integration and Automation</h1>
            <p className="text-center">
            Streamline workflows and boost productivity through automation. We minimize errors in FinTech, ensure timely compliance in GRC, and optimize clinical workflows in healthcare.
            </p>
            <button className="bg-[#210d94] text-white px-4 py-4 ml-2 rounded-full text-base rounded-br-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
          <motion.div
            className="flex justify-evenly items-center flex-col bg-white p-5 min-h-96 shadow-lg w-1/4"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <IconWifi size={60} />
            <h1 className="text-3xl text-center">Managed Services</h1>
            <p className="text-center">
            Receive comprehensive support for IT infrastructure and applications. Our services ensure security, compliance, and optimal performance across FinTech, GRC, and healthcare systems.
            </p>
            <button className="bg-pink-500 text-white px-4 py-4 ml-2 rounded-full text-base rounded-tl-lg font-extrabold">
              Find out more
            </button>
          </motion.div>
        </div>
        <div className="absolute bottom-0 right-0 w-full">
          <img src={CurveRightBottom} alt="Design" className="w-full" />
        </div>
      </section>
      <section className="z-0 flex pt-40 flex-col bg-[#210d94] py-32 justify-center overflow-x-hidden">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <motion.div
            className="w-full md:w-2/5 mt-12 md:mt-0 z-20 md:max-w-2xl max-w-full"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <img
              src={g2}
              alt="Technology for Innovators"
            />
          </motion.div>
          <motion.div
            className="w-full md:w-3/5 text-center md:text-left font-thin p-14 flex gap-10 flex-col"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: false }}
          >
            <h1 className="text-5xl md:text-5xl text-white">
            Innovating the<br />
              <span className="text-pink-500 font-extrabold">
              Digital Frontier
              </span>
            </h1>
            <div className="flex flex-row gap-10  text-justify">
              <p className="text-white mt-4 w-full md:w-1/2">
              Transform your business with our state-of-the-art technology and strategic expertise. We specialize in integrating advanced solutions to enhance efficiency, drive growth, and maintain a competitive edge in the digital age.
              </p>
              <p className="text-white mt-4 w-full md:w-1/2">
              Our comprehensive approach combines the latest in digital transformation, robust cloud solutions, and seamless data integration to deliver exceptional results. Partner with us to harness the full potential of technology and drive your business to new heights of success.
              </p>
            </div>
            <div className="flex flex-col md:flex-row mt-8 space-y-4 md:space-y-0 md:space-x-20">
              <motion.div
                className="text-center flex justify-center flex-col items-center gap-3"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }}
              >
                <ProgressBar progress={95} Icon={IconThumbUpFilled} />
                <h3 className="text-3xl font-bold text-white">Growth</h3>
                <p className="text-white mt-2">
                Harness next-gen technology to streamline processes and drive impactful results.
                </p>
              </motion.div>
              <motion.div
                className="text-center flex justify-center flex-col items-center gap-3"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }}
              >
                <ProgressBar progress={80} Icon={IconWorld} />
                <h3 className="text-3xl font-bold text-white">Career</h3>
                <p className="text-white mt-2">
                Foster professional development and innovative thinking through dynamic digital projects.
                </p>
              </motion.div>
              <motion.div
                className="text-center flex justify-center flex-col items-center gap-3"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: false }}
              >
                <ProgressBar progress={70} Icon={IconMessages} />
                <h3 className="text-3xl font-bold text-white">Communication</h3>
                <p className="text-white mt-2">
                Deliver effective and scalable solutions with a focus on clarity and performance.
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </section>
      <section className="bg-[#f5f5f5] py-8">
        <div className="container mx-auto">
          <Slider {...settingsclients}>
            {clients.map((item, index) => (
              <div key={index} className="p-4">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-48 h-auto mx-auto"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="relative overflow-x-hidden">
        <div className="absolute top-0 left-0 w-full">
          <img
            src={CurveBottomLeftGray}
            alt="Section Coverage Image"
            className="w-full"
          />
        </div>

        <motion.div
          className="flex flex-col md:flex-row items-center justify-center bg-white px-8 py-20"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        >
          <div className="w-full md:w-1/2 text-center md:text-left p-24">
            <h1 className="text-5xl md:text-5xl font-extrabold text-pink-500">
              Get In
              <span className="text-5xl md:text-5xl font-extrabold text-blue-900 ml-1">
                Touch
              </span>
            </h1>
            <p className="text-gray-600 mt-4">
              Visit our agency or simply send us an email anytime you want. If
              you have any questions, please feel free to contact us.
            </p>
            <hr
              className="my-10"
              style={{
                backgroundColor: "#2222221a",
                height: 2,
              }}
            />
            <motion.div
              className="flex flex-row justify-center items-center my-5"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
            >
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconBuildings size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Main Office</h1>
                    <p className="text-sm pt-1">
                    Alnsab/ Bousher/ Muscat Governorate, P.O.Box 123 Postal Code 111
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconBuildingSkyscraper size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Creative Office</h1>
                    <p className="text-sm pt-1">
                    Alnsab/ Bousher/ Muscat Governorate, P.O.Box 123 Postal Code 111
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
            <motion.div
              className="flex flex-row justify-center items-center my-5"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
            >
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconPhoneCall size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Call us</h1>
                    <p className="text-sm pt-1">
                      310-734-9721
                      <br />
                      310-401-3336
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconMail size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Email us</h1>
                    <p className="text-sm pt-1">
                      office@example.com
                      <br />
                      info@example.com
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          <motion.div
            className="w-full md:w-1/2 z-20"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={b2}
              className="rounded-full"
              alt="Technology for Innovators"
            />
          </motion.div>
        </motion.div>
      </section>
    </div>
  );
}

export default Services;
