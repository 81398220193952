import React from "react";
import CurveBottomLeft from "../../assets/images/gray-curve-bottom-left.png";
import WhiteLeft from "../../assets/images/gray-curve-bottom-left-white-2.png";
import { IconSearch } from "@tabler/icons-react";
import Slider from "react-slick";
import "./styles.css";
import Header from "../../Components/Header";
import { motion } from "framer-motion";
import a3 from "../../assets/images/a3.png";
import imthyaz from "../../assets/images/imthyaz.avif";
import karthick from "../../assets/images/karthick.avif";
import Ramachandran from "../../assets/images/Ramachandran.png";
import vishal from "../../assets/images/vishal.jpeg";

function About() {
  const settingsclients = {
    lazyLoad: "progressive",
    cssEase: "ease-out",
    speed: 300,
    adaptiveHeight: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
  };
  const clients = [
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-estato.png",
      alt: "logo-estato",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-bello.png",
      alt: "logo-bello",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-applauz.png",
      alt: "logo-applauz",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-california.png",
      alt: "logo-hotel-california",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-celeste.png",
      alt: "logo-celeste",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-berg.png",
      alt: "logo-hotel-berg",
    },
  ];
  const settingsclients1 = {
    lazyLoad: "progressive",
    cssEase: "ease-out",
    speed: 300,
    adaptiveHeight: true,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
    ],
  };
  const ProgressBar = ({ value, text }) => (
    <div className="flex flex-col items-start space-y-2">
      <span className="text-gray-700 font-semibold">{text}</span>
      <div className="relative w-full bg-gray-200 rounded-full h-1">
        <div
          className="absolute top-0 left-0 h-full rounded-full bg-[#fb17ce]"
          style={{ width: `${value}%` }}
        />
      </div>
    </div>
  );
  return (
    <div>
      <Header transparent={true} />
      <section
        className="overflow-x-hidden bg-cover bg-no-repeat relative"
        style={{
          backgroundImage:
            "url('https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/bgn-slide-01.jpg')",
        }}
      >
        <div className="flex flex-row h-screen items-center">
          <motion.div
            className="w-1/2 flex flex-col justify-center items-end px-20"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            <h1 className="text-8xl font-bold text-white text-right">
              <p className="text-white font-light">
                Driving Digital Transformation
              </p>
              <span className="text-pink-500">for the Future</span>
            </h1>
            <motion.p
              className="text-lg font-light text-white mb-4 text-right mt-5"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: false }}
            >
              Our mission is to empower businesses with tools and strategies
              that drive growth and efficiency in today’s digital world.
            </motion.p>
            <div>
              <motion.button
                className="bg-pink-500 text-white px-8 py-6 ml-2 rounded-full text-base rounded-tl-lg font-extrabold"
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                viewport={{ once: false }}
              >
                Ready to Transform?
              </motion.button>
              <motion.button
                className="bg-white text-pink-500 px-8 py-6 ml-2 rounded-full text-base rounded-br-lg"
                initial={{ opacity: 0, scale: 0.8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                viewport={{ once: false }}
              >
                Contact Us
              </motion.button>
            </div>
          </motion.div>
          <div
            className="w-1/2 h-full bg-contain bg-no-repeat bg-right-bottom z-10"
            style={{
              backgroundImage: `url(https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/10/img-slider-02.png)`,
              backgroundSize: "100%",
            }}
          />
        </div>
        <div className="absolute bottom-0 left-0">
          <img src={CurveBottomLeft} alt="Designs Curves" />
        </div>
      </section>
      <section className="z-0 flex px-40 pt-40 flex-col bg-[#f5f5f5] py-32 justify-center">
        <motion.h1
          className="text-5xl md:text-5xl font-extrabold text-pink-500"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: false }}
        >
          Simple Solutions for <br />
          <span className="text-[#210d94]">Complex Challenges</span>
        </motion.h1>
        <div className="py-10 flex flex-col">
          <div className="slider-container">
            <Slider {...settingsclients}>
            <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={ require('../../assets/images/icons/always connected.svg').default} width={60} alt="Always Connected"/>
            <h3 className="text-2xl font-normal">Always Connected</h3>
            <p className="pt-1 text-sm">
            We simplify complex challenges, ensuring your business stays agile and ahead in a fast-paced digital world.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={ require('../../assets/images/icons/seamless integration.svg').default} width={60} alt="Seamless Integration"/>
            <h3 className="text-2xl font-normal">Seamless Integration</h3>
            <p className="pt-1 text-sm">
            Integrate our state-of-the-art digital solutions into your existing frameworks by streamlines operations, and accelerate growth without disruption.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 2 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={ require('../../assets/images/icons/innovation.svg').default} width={60} alt="Real-Time Innovation"/>
            <h3 className="text-2xl font-normal">Real-Time Innovation</h3>
            <p className="pt-1 text-sm">
            Instant Transformation leverage our real-time capabilities to drive operational excellence and data-driven decisions.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 3 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={ require('../../assets/images/icons/global expertise.svg').default} width={60} alt="Global Digital Expertise"/>
            <h3 className="text-2xl font-normal">Global Digital Expertise</h3>
            <p className="pt-1 text-sm">
            Benefit from international digital solutions designed to foster innovation and growth across diverse markets. We help you scale globally with ease.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={ require('../../assets/images/icons/holistic digital.svg').default} width={60} alt="Holistic Digital Approach"/>
            <h3 className="text-2xl font-normal">Holistic Digital Approach</h3>
            <p className="pt-1 text-sm">
            We combine ethical practices with a focus on sustainable digital growth to future-proof your business.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 2 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={ require('../../assets/images/icons/Collabrative.svg').default} width={60} alt="Client-Centric Solutions"/>
            <h3 className="text-2xl font-normal">Client-Centric Solutions</h3>
            <p className="pt-1 text-sm">
            Partner with us to create tailored digital strategies that meet your specific needs. We ensures high-quality results and accelerated digital transformation.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 3 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img src={ require('../../assets/images/icons/Future solution.svg').default} width={60} alt="Future-Ready Solutions"/>
            <h3 className="text-2xl font-normal">Future-Ready Solutions</h3>
            <p className="pt-1 text-sm">
            We equip your business with the tools needed to thrive in the evolving digital landscape.
            </p>
          </motion.div>
            </Slider>
          </div>
        </div>
      </section>
      <section className="relative z-0 flex px-20 flex-col bg-white py-40 justify-center">
        <div className="px-20">
          <motion.h1
            className="text-5xl md:text-5xl font-extrabold text-pink-500"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            Driving Innovation and Excellence <br />
            <span className="text-[#210d94]">in Digital Transformation</span>
          </motion.h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-10">
            <motion.div
              className="flex flex-col space-y-4 p-4 text-justify"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: false }}
            >
              <ProgressBar value={98} text="Teamwork" />
              <ProgressBar value={92} text="Benefits" />
              <ProgressBar value={89} text="Personal Growth" />
              <p>
                At Al Jazeera Technology Solutions, we are dedicated to
                empowering innovation with cutting-edge technology. Our
                collaborative approach fosters seamless teamwork , driving
                digital transformation and optimizing workflows. We offer a
                range of benefits including high-impact solutions that enhance
                performance and growth.
              </p>
            </motion.div>
            <motion.div
              className="flex flex-col space-y-4 p-4 text-justify"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: false }}
            >
              <p>
                Our commitment to personal growth is reflected in our support
                for continuous learning and development, ensuring that you stay
                ahead in the digital landscape.
              </p>
              <p>
                We focus on delivering innovative solutions that revolutionize
                your business with future-proof technology. Our emphasis on
                efficient processes ensures quick implementation of effective
                strategies, tailored to meet your needs.
              </p>
              <p>
                Prioritizing customer satisfaction, we provide a
                customer-centric approach with dedicated service and impactful
                solutions
              </p>
            </motion.div>
            <motion.div
              className="flex flex-col space-y-4 p-4"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: false }}
            >
              <p>
                Embrace the future with our cutting-edge technologies and
                strategic insights, as we help you shape the future and turn
                your vision into reality.
              </p>
              <blockquote className="border-l-4 border-[#fb17ce] pl-4 italic">
                Sometimes the best way to envision the future is to invent it.
              </blockquote>
            </motion.div>
          </div>
        </div>
        <div className="flex flex-col">
          <motion.div
            className="slider-container"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            <Slider {...settingsclients}>
              {[...Array(8).keys()].map((_, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.9 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: false }}
                >
                  <img
                    src="https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/09/blog-18.jpg"
                    alt={`blog-18-${index}`}
                  />
                </motion.div>
              ))}
            </Slider>
          </motion.div>
        </div>
        <div className="absolute bottom-0 left-0 w-full">
          <img
            src={CurveBottomLeft}
            alt="Bottom Section Coverage Image"
            className="w-full"
          />
        </div>
      </section>
      <section className="z-0 flex px-10 pt-40 flex-col bg-[#f5f5f5] py-32 justify-center">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <motion.div
            className="w-full md:w-1/2 text-center md:text-left p-24"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <h1 className="text-5xl md:text-5xl font-extrabold text-pink-500">
              Our Numbers,
            </h1>
            <h1 className="text-5xl md:text-5xl font-extrabold text-blue-900 mt-2">
              Our Growth
            </h1>
            <p className="text-gray-600 mt-4">
              At Al Jazeera Technology Solutions, our rapid growth and impactful
              contributions are reflected in the numbers we’ve achieved. Each
              statistic represents a milestone in our journey to revolutionize
              the healthcare, fintech, and GRC industries. From onboarding key
              clients to delivering innovative solutions, our numbers tell the
              story of a company that’s quickly making its mark in the
              technology landscape.
            </p>
            <div className="flex flex-col md:flex-row mt-8 space-y-4 md:space-y-0 md:space-x-8">
              <motion.div
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <h3 className="text-6xl font-bold text-blue-900">100%</h3>
                <p className="text-gray-600 mt-2">Focused Support</p>
              </motion.div>
              <motion.div
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <h3 className="text-6xl font-bold text-pink-500">3</h3>
                <p className="text-gray-600 mt-2">Key Industries</p>
              </motion.div>
              <motion.div
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <h3 className="text-6xl font-bold text-teal-500">15+</h3>
                <p className="text-gray-600 mt-2">Industry Experts</p>
              </motion.div>
            </div>
            <motion.div
              className="mt-8"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              viewport={{ once: true, amount: 0.5 }}
            >
              <button className="bg-blue-900 text-white py-3 px-6 rounded-full rounded-tl-lg">
                Find out more
              </button>
            </motion.div>
          </motion.div>
          <motion.div
            className="w-full md:w-1/2 mt-12 md:mt-0 z-20"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }} // Ensures animation when in view
            transition={{ duration: 0.8 }}
            viewport={{ once: false }} // Ensures animation triggers every time it comes into view
          >
            <img
              src={a3}
              alt="Technology for Innovators"
            />
          </motion.div>
        </div>
      </section>
      <section className="relative overflow-hidden bg-cover bg-no-repeat py-20 lg:py-32">
        <div className="relative z-10 max-w-6xl mx-auto px-4 lg:px-0">
          <motion.div
            className="grid grid-cols-1 lg:grid-cols-1 gap-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            <div className="flex flex-col justify-center">
              <h1 className="text-5xl md:text-5xl font-extrabold text-pink-500">
                Guiding with
                <br />
                <span className="text-[#210d94]">Vision and Innovation</span>
              </h1>
              <p className="mt-6">
                Our leadership team at Al Jazeera Technology Solutions blends
                strategic vision with deep industry expertise to drive
                innovation across healthcare, FinTech, and GRC sectors. By
                fostering a culture of collaboration and continuous improvement,
                they lead the way in delivering cutting-edge solutions that meet
                the evolving needs of our clients.
              </p>
            </div>
          </motion.div>

          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            {[
              {
                name: "Naif Hamed Omar Fadhil",
                imgSrc:
                  "https://grandgrp.co/wp-content/uploads/2024/02/20240218_102805-scaled-e1708924809842.jpg",
                description: "Managing Director & Chief Executive Officer",
              },
              {
                name: "Ramachandran MV",
                imgSrc: Ramachandran,
                description: "Group Chief Financial Officer",
              },
              {
                name: "Vishal Srinivasan",
                imgSrc: vishal,
                description: "Group Portfolio Strategist",
              },
              {
                name: "Karthik Gunasekar",
                imgSrc: karthick,
                description: "Chief Technology Officer",
              },
              {
                name: "Imthyaz Sheriff",
                imgSrc: imthyaz,
                description: "Chief Business Strategist",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                className="flex flex-col items-start text-center lg:text-left"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <div className="relative">
                  <a href="#" target="_self">
                    <img
                      loading="lazy"
                      decoding="async"
                      src={item.imgSrc}
                      alt={item.name}
                      className="w-full h-auto rounded object-cover"
                    />
                    <div className="absolute inset-0 flex items-end p-4">
                      <div className="text-right ml-auto">
                        <div
                          className="flex items-center justify-center w-10 h-10 rounded-full text-white"
                          style={{ backgroundColor: "#210d94" }}
                        >
                          <IconSearch />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <h3 className="text-3xl text-[#210D94] font-bold mt-4">
                  {item.name}
                </h3>
                <p className="mt-2">{item.description}</p>
                <a
                  href="#"
                  target="_self"
                  className="mt-4 inline-block bg-pink-500 text-white py-2 px-4 rounded-br-lg rounded-full"
                >
                  Read more
                </a>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      <section className="relative overflow-hidden min-h-screen">
        <div className="absolute bottom-0 left-0 w-full">
          <img src={WhiteLeft} alt="Design Curve" className="w-full" />
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.858406497801!2d58.39050367537303!3d23.53759457881607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e91fe0ce7096cb7%3A0xe4cd279713f58029!2sBousher!5e0!3m2!1sen!2sin!4v1724911077970!5m2!1sen!2sin"
            width="100%"
            height="900px"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <section className="py-8">
        <div className="container mx-auto">
          <Slider {...settingsclients1}>
            {clients.map((item, index) => (
              <div key={index} className="p-4">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-48 h-auto mx-auto"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default About;
