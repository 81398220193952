import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./Screens/Home";
import About from "./Screens/About";
import Contact from "./Screens/Contact";
import Services from "./Screens/Services";
import PageTitle from "./Components/PageTitle";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <PageTitle title="Home" />
              <Home />
            </>
          }
        />
        <Route
          path="/aboutus"
          element={
            <>
              <PageTitle title="About Us" />
              <About />
            </>
          }
        />
        <Route
          path="/services"
          element={
            <>
              <PageTitle title="Services" />
              <Services />
            </>
          }
        />
        <Route
          path="/contactus"
          element={
            <>
              <PageTitle title="Contact Us" />
              <Contact />
            </>
          }
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
