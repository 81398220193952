import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../assets/images/Logo.png"
import LogoWhite from "../assets/images/Logo-white.png"

const Header = ({transparent}) => {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`z-50 fixed top-0 left-0 w-full transition-all duration-500 ease-in-out ${
        isSticky ? `bg-white shadow-md` : transparent?"bg-transparent":"bg-[#210d94] h-20"
      }`}
    >
      <div className="max-w-8xl mx-auto px-4 py-2 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <NavLink
              to="/"
              className={`text-2xl font-bold ${
                isSticky ? "text-gray-800" : "text-white"
              }`}
            >
              <img src={isSticky?Logo:LogoWhite} alt="Site Logo" width={"20%"}/>
            </NavLink>
          </div>
          <nav className="hidden md:flex space-x-8  w-full lg:flex justify-end">
            <NavLink
              to="/"
              className={`relative text-gray-800 hover:text-pink-500 ${
                isSticky ? "text-gray-800" : "text-white"
              } ${location.pathname === "/" ? "active" : ""}`}
            >
              Home
              {location.pathname === "/" && (
                <span
                  className={`absolute left-1/2 transform -translate-x-1/2 -bottom-1 h-1 w-1 rounded-full ${
                    isSticky ? "bg-purple-600" : "bg-pink-500"
                  }`}
                />
              )}
            </NavLink>
            <NavLink
              to="/aboutus"
              className={`relative text-gray-800 hover:text-pink-500 ${
                isSticky ? "text-gray-800" : "text-white"
              } ${location.pathname === "/aboutus" ? "active" : ""}`}
            >
              About Us
              {location.pathname === "/aboutus" && (
                <span
                  className={`absolute left-1/2 transform -translate-x-1/2 -bottom-1 h-1 w-1 rounded-full ${
                    isSticky ? "bg-purple-600" : "bg-pink-500"
                  }`}
                />
              )}
            </NavLink>
            <NavLink
              to="/services"
              className={`relative text-gray-800 hover:text-pink-500 ${
                isSticky ? "text-gray-800" : "text-white"
              } ${location.pathname === "/services" ? "active" : ""}`}
            >
              Services
              {location.pathname === "/services" && (
                <span
                  className={`absolute left-1/2 transform -translate-x-1/2 -bottom-1 h-1 w-1 rounded-full ${
                    isSticky ? "bg-purple-600" : "bg-pink-500"
                  }`}
                />
              )}
            </NavLink>
            <NavLink
              to="/contactus"
              className={`relative text-gray-800 hover:text-pink-500 ${
                isSticky ? "text-gray-800" : "text-white"
              } ${location.pathname === "/contactus" ? "active" : ""}`}
            >
              Contact Us
              {location.pathname === "/contactus" && (
                <span
                  className={`absolute left-1/2 transform -translate-x-1/2 -bottom-1 h-1 w-1 rounded-full ${
                    isSticky ? "bg-purple-600" : "bg-pink-500"
                  }`}
                />
              )}
            </NavLink>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;