import React, { useState } from "react";
import {
  IconBuildingSkyscraper,
  IconBuildings,
  IconPhoneCall,
  IconMail,
  IconClock,
  IconAugmentedReality,
  IconAward,
  IconBriefcase,
  IconMedal,
  IconTableOptions,
  IconWorldUpload,
} from "@tabler/icons-react";
import Header from "../../Components/Header";
import GrayBottomRight from "../../assets/images/gray-curve-bottom-right.png";
import Slider from "react-slick";
import "./styles.css";
import TopLeftCurve from "../../assets/images/gray-curve-bottom-left-white-2.png";
import { motion } from "framer-motion";
import a2 from "../../assets/images/a2.png";
import b2 from "../../assets/images/b2.png";

function Contact() {
  const clients = [
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-estato.png",
      alt: "logo-estato",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-bello.png",
      alt: "logo-bello",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-applauz.png",
      alt: "logo-applauz",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-california.png",
      alt: "logo-hotel-california",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-celeste.png",
      alt: "logo-celeste",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-berg.png",
      alt: "logo-hotel-berg",
    },
  ];
  const settingsclients = {
    lazyLoad: "progressive",
    cssEase: "ease-out",
    speed: 300,
    adaptiveHeight: true,
    dots: true,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
    ],
  };
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email Triggered:", email);
  };
  return (
    <div>
      <Header transparent={false} />
      <section>
        <motion.div
          className="flex flex-col md:flex-row items-center justify-center bg-white px-8 py-20 mt-20"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        >
          <motion.div
            className="w-full md:w-1/2 z-20"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={b2}
              className="rounded-full"
              alt="Technology for Innovators"
            />
          </motion.div>

          <motion.div
            className="w-full md:w-1/2 text-center md:text-left p-24"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl md:text-5xl font-extrabold text-pink-500">
              Visit us
              <span className="text-5xl md:text-5xl font-extrabold text-blue-900 ml-1">
                Anytime
              </span>
            </h1>
            <p className="text-gray-600 mt-4">
              Visit our office or simply send us an email anytime you want. If
              you have any questions, please feel free to contact us.
            </p>
            <hr
              className="my-10"
              style={{
                backgroundColor: "#2222221a",
                height: 2,
              }}
            />

            <motion.div
              className="flex flex-row justify-center items-center my-5"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
            >
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconBuildings size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Main Office</h1>
                    <p className="text-sm pt-1">
                    Alnsab/ Bousher/ Muscat Governorate, P.O.Box 123 Postal Code 111
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconBuildingSkyscraper size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Creative Office</h1>
                    <p className="text-sm pt-1">
                    Alnsab/ Bousher/ Muscat Governorate, P.O.Box 123 Postal Code 111
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="flex flex-row justify-center items-center my-5"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
            >
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconPhoneCall size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Call us</h1>
                    <p className="text-sm pt-1">
                      310-734-9721
                      <br />
                      310-401-3336
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 text-center md:text-left">
                <div className="flex flex-row justify-center items-center gap-3">
                  <div className="w-full md:w-1/5 text-center md:text-left">
                    <IconMail size={48} color={"#210d94"} />
                  </div>
                  <div className="w-full md:w-4/5 text-center md:text-left">
                    <h1 className="text-2xl">Email us</h1>
                    <p className="text-sm pt-1">
                      office@example.com
                      <br />
                      info@example.com
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
      </section>
      <section className="bg-[#210d94] text-white p-8 min-h-screen relative">
        <div className="mx-auto py-16 px-16">
          <div className="flex flex-wrap">
            <motion.div
              className="w-full lg:w-2/3 flex flex-col p-20"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-6xl md:text-6xl text-white">
                Stay Update
                <br />
                <span className="text-pink-500 font-extrabold">
                  Subscribe Now
                </span>
              </h1>
              <div className="mt-4">
                Keep up with Al Jazeera and get instant news once in a while. We
                promise, no spam or similar emails, just pure goodiness.
                Enthusiastically leverage existing premium quality vectors with
                enterprise-wide innovation. Phosfluorescently leverage others'
                high impact solutions outside-the-box thinking with e-business
                ideas.
              </div>
              <form
                onSubmit={handleSubmit}
                className="flex items-center space-x-4 my-10"
              >
                <div className="flex-grow">
                  <input
                    type="email"
                    name="your-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 py-5 px-8 rounded-full"
                    placeholder="Enter your email address"
                    aria-required="true"
                    aria-invalid="false"
                    maxLength="400"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="bg-pink-500 text-white py-4 px-6 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-full rounded-tl-lg text-2xl"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </motion.div>
            <motion.div
              className="w-full lg:w-1/3 flex items-center lg:justify-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
            >
              <img
                src={a2}
                className="rounded-full"
                alt="Technology for Innovators"
              />
            </motion.div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <img src={GrayBottomRight} alt="Designs Curves" />
        </div>
      </section>
      <section className="z-0 flex px-40 py-36 flex-col bg-[#f5f5f5] relative">
        <motion.h1
          className="text-5xl md:text-5xl font-extrabold text-pink-500"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        >
          Join Al Jazeera Technology Solutions Team
          <br />
          <span className="text-[#210d94]">Innovate with Us</span>
        </motion.h1>
        <motion.p
          className="my-5 w-full md:w-1/2"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        >
          Be part of a forward-thinking team dedicated to revolutionizing the
          digital landscape. At Al Jazeera Technology Solutions, we push the
          boundaries of technology to deliver transformative solutions.
        </motion.p>
        <motion.hr
          className="my-10"
          style={{
            backgroundColor: "#2222221a",
            height: 2,
          }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        />
        <Slider {...settingsclients}>
          <motion.div
            className="pr-5"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <IconClock color="#210d94" size={60} />
            <h3 className="text-2xl font-normal">Flexible Work Hours</h3>
            <p className="pt-1 text-sm">
              Enjoy the flexibility to work according to your schedule while
              collaborating with global experts.
            </p>
          </motion.div>
          <motion.div
            className="pr-5"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <IconAugmentedReality color="#210d94" size={60} />
            <h3 className="text-2xl font-normal">Cutting-Edge Technology</h3>
            <p className="pt-1 text-sm">
              Utilize the latest advancements in digital transformation and
              innovation in your projects.
            </p>
          </motion.div>
          <motion.div
            className="pr-5"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <IconAward color="#210d94" size={60} />
            <h3 className="text-2xl font-normal">Award-Winning Expertise</h3>
            <p className="pt-1 text-sm">
              Be part of a team renowned for excellence in delivering impactful
              digital solutions.
            </p>
          </motion.div>
          <motion.div
            className="pr-5"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <IconBriefcase color="#210d94" size={60} />
            <h3 className="text-2xl font-normal">Dynamic Work Environment</h3>
            <p className="pt-1 text-sm">
              Thrive in a creative and collaborative atmosphere, where diverse
              ideas and talents come together.
            </p>
          </motion.div>
          <motion.div
            className="pr-5"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <IconMedal color="#210d94" size={60} />
            <h3 className="text-2xl font-normal">Remarkable Achievements</h3>
            <p className="pt-1 text-sm">
              Contribute to high-impact projects that drive significant change
              and industry advancement.
            </p>
          </motion.div>
          <motion.div
            className="pr-5"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <IconTableOptions color="#210d94" size={60} />
            <h3 className="text-2xl font-normal">Challenging Projects</h3>
            <p className="pt-1 text-sm">
              Engage in complex and stimulating projects that push the
              boundaries of technology and creativity.
            </p>
          </motion.div>
          <motion.div
            className="pr-5"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            <IconWorldUpload color="#210d94" size={60} />
            <h3 className="text-2xl font-normal">Global Impact</h3>
            <p className="pt-1 text-sm">
              Work on solutions with a worldwide influence, shaping technology
              and business practices across the globe.
            </p>
          </motion.div>
        </Slider>
        <div className="flex flex-row justify-center mt-32">
          <motion.button
            className="bg-[#210d94] text-white px-6 py-4 ml-2 rounded-full text-base rounded-br-lg font-extrabold"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            Contact us anytime
          </motion.button>
          <motion.button
            className="bg-[#fb17ce] text-white px-6 py-4 ml-2 rounded-full text-base rounded-tl-lg"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
          >
            Apply for Open positions
          </motion.button>
        </div>
        <div className="absolute bottom-0 left-0">
          <img src={TopLeftCurve} alt="Designs Curves" />
        </div>
      </section>
      <section className="py-8">
        <div className="container mx-auto">
          <Slider {...settingsclients}>
            {clients.map((item, index) => (
              <div key={index} className="p-4">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-48 h-auto mx-auto"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default Contact;
