import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = ({ title }) => {
  const SiteName = "Al Jazeera";
  const location = useLocation();

  useEffect(() => {
    document.title = title + " - " + SiteName;
  }, [location, title]);

  return null;
};

export default PageTitle;
