import React from "react";
import Slider from "react-slick";
import CurveBtmLeft from "../../assets/images/gray-curve-bottom-left.png";
import CurveTopRight from "../../assets/images/curve-top-right.png";
import { IconQuote, IconSearch } from "@tabler/icons-react";
import Header from "../../Components/Header";
import { motion } from "framer-motion";
import g1 from "../../assets/images/g1.png";
import g2 from "../../assets/images/g2.png";
import a1 from "../../assets/images/a1.png";
import a11 from "../../assets/images/a1_bak.png";
import a2 from "../../assets/images/a2.png";
import a3 from "../../assets/images/a3.png";
import b1 from "../../assets/images/b1.png";
import imthyaz from "../../assets/images/imthyaz.avif";
import karthick from "../../assets/images/karthick.avif";
import Ramachandran from "../../assets/images/Ramachandran.png";
import vishal from "../../assets/images/vishal.jpeg";

function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  const settingstestimonials = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  const clients = [
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-estato.png",
      alt: "logo-estato",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-bello.png",
      alt: "logo-bello",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-applauz.png",
      alt: "logo-applauz",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-california.png",
      alt: "logo-hotel-california",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-celeste.png",
      alt: "logo-celeste",
    },
    {
      src: "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/logo-hotel-berg.png",
      alt: "logo-hotel-berg",
    },
  ];
  const settingsclients = {
    lazyLoad: "progressive",
    cssEase: "ease-out",
    speed: 300,
    adaptiveHeight: true,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
    ],
  };

  const testimonials = [
    {
      quote: "The best team out there",
      text: "Seamlessly empower fully researched growth strategies and interoperable internal or organic sources.",
      rating: 5,
      author: "Emma Hewitt",
    },
    {
      quote: "Outstanding service!",
      text: "Efficiently unleash cross-media information without cross-media value.",
      rating: 4,
      author: "John Doe",
    },
    {
      quote: "Highly recommend",
      text: "Dynamically innovate resource-leveling customer service for state of the art customer service.",
      rating: 5,
      author: "Jane Smith",
    },
  ];

  const slides = [
    {
      title: "Empowering Your Vision with Cutting-Edge IT Solutions",
      subtitle: "",
      description:
        "Our innovative IT services are designed to accelerate your business growth, optimize operations, and deliver exceptional results. Let's transform your challenges into opportunities together.",
      image:
        "https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/10/img-slider-02.png",
      buttonText1: "Discover Our Services",
      buttonText2: "Contact us",
    },
  ];
  return (
    <div>
      <Header transparent={true} />
      <section
        className="slider-container overflow-x-hidden bg-cover bg-no-repeat"
        style={{
          backgroundImage:
            "url('https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/bgn-slide-01.jpg')",
        }}
      >
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <motion.div
              key={index}
              className="!flex !flex-row h-screen items-center overflow-x-hidden"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <div className="xl:w-1/2 lg:w-3/5 flex flex-col justify-center items-end px-20">
                <motion.h1
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: false }}
                  transition={{ duration: 1, ease: "easeInOut" }}
                  className="lg:text-7xl md:text-5xl text-3xl font-bold text-white text-right"
                >
                  <p className="text-white font-light">
                    Empowering Your Vision with
                  </p>
                  <span className="text-pink-500">
                    Cutting-Edge IT Solution
                  </span>
                </motion.h1>
                <motion.p
                  className="text-lg font-light text-white mb-4 text-right mt-5"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  {slide.subtitle}
                  {slide.description}
                </motion.p>
                <motion.div
                  className="flex lg:flex-row gap-5 flex-col"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  <motion.button
                    className="bg-pink-500 text-white px-8 py-6 ml-2 rounded-full text-base rounded-tl-lg font-extrabold"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    {slide.buttonText1}
                  </motion.button>
                  <motion.button
                    className="bg-white text-pink-500 px-8 py-6 ml-2 rounded-full text-base rounded-br-lg"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    {slide.buttonText2}
                  </motion.button>
                </motion.div>
              </div>
              <motion.div
                className="xl:w-1/2 lg:w-2/5 h-full bg-contain bg-no-repeat bg-right-bottom"
                style={{
                  backgroundImage: `url(${slide.image})`,
                  backgroundSize: "90%",
                }}
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              />
            </motion.div>
          ))}
        </Slider>
      </section>
      <section className="flex justify-center mx-48 items-center">
        <motion.div
          className="text-white m-4"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <div className="h-[250px] w-full ">
            <img
              src={g1}
              alt="img"
              className="h-full w-full object-cover rounded-tl-[50%] object-top"
            />
          </div>

          <div className="bg-[#210d92] p-6">
            <h3 className="text-3xl mt-4">
              Innovate Beyond
              <br />
              Boundaries
            </h3>
            <p className="text-base mt-4">
              Harness the power of technology to unlock limitless possibilities.
              We blend advanced solutions with strategic insights to drive
              exceptional outcomes and set new industry standards.
            </p>
            <motion.button
              className="bg-white text-pink-500 py-3 font-bold px-6 my-5 rounded-full text-base rounded-br-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Find out more
            </motion.button>
          </div>
        </motion.div>

        <motion.div
          className="text-white m-4"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        >
          <div className="h-[250px] w-full ">
            <img
              src={a2}
              alt="img"
              className="h-full w-full object-cover rounded-t-[25%]  object-top"
            />
          </div>
          <div className="bg-[#fb17ce] p-6">
            <h3 className="text-3xl mt-4">
              Transform with
              <br />
              Precision
            </h3>
            <p className="text-base mt-4">
              Empower your business with tailored solutions that optimize
              processes and streamline operations. Our expertise in integrating
              cutting-edge technology ensures a seamless transformation aligned
              with your strategic goals.
            </p>
            <motion.button
              className="bg-white text-pink-500 py-3 font-bold px-6 my-5 rounded-full text-base rounded-br-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Find out more
            </motion.button>
          </div>
        </motion.div>

        <motion.div
          className="text-white m-4"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.3 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
        >
          <div className="h-[250px] w-full ">
            <img
              src={a11}
              alt="img"
              className="h-full w-full object-cover rounded-tr-[50%]  "
              style={{ backgroundPosition: "center" }}
            />
          </div>
          <div className="bg-[#7517cd] p-6">
            <h3 className="text-3xl mt-4">
              Pioneer Tomorrow’s
              <br />
              Solutions
            </h3>
            <p className="text-base mt-4">
              Lead the way in innovation with Al Jazeera’s forward-thinking
              technology. Our commitment to excellence and collaborative
              approach ensure that we deliver solutions that are both visionary
              and effective.
            </p>
            <motion.button
              className="bg-white text-pink-500 py-3 font-bold px-6 my-5 rounded-full text-base rounded-br-lg"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Find out more
            </motion.button>
          </div>
        </motion.div>
      </section>
      <section className="flex justify-center min-h-screen z-10">
        <div className="flex flex-col md:flex-row items-center justify-between bg-white py-12 px-8">
          {/* Left Column with Text Content */}
          <motion.div
            className="w-full md:w-1/2 text-center md:text-left p-24"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <h1 className="text-5xl md:text-5xl font-extrabold text-pink-500">
              Our Numbers,
            </h1>
            <h1 className="text-5xl md:text-5xl font-extrabold text-blue-900 mt-2">
              Our Growth
            </h1>
            <p className="text-gray-600 mt-4">
              At Al Jazeera Technology Solutions, our rapid growth and impactful
              contributions are reflected in the numbers we’ve achieved. Each
              statistic represents a milestone in our journey to revolutionize
              the healthcare, fintech, and GRC industries. From onboarding key
              clients to delivering innovative solutions, our numbers tell the
              story of a company that’s quickly making its mark in the
              technology landscape.
            </p>
            <div className="flex flex-col md:flex-row mt-8 space-y-4 md:space-y-0 md:space-x-8">
              <motion.div
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <h3 className="text-6xl font-bold text-blue-900">100%</h3>
                <p className="text-gray-600 mt-2">Focused Support</p>
              </motion.div>
              <motion.div
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <h3 className="text-6xl font-bold text-pink-500">3</h3>
                <p className="text-gray-600 mt-2">Key Industries</p>
              </motion.div>
              <motion.div
                className="text-center"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <h3 className="text-6xl font-bold text-teal-500">15+</h3>
                <p className="text-gray-600 mt-2">Industry Experts</p>
              </motion.div>
            </div>
            <motion.div
              className="mt-8"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              viewport={{ once: true, amount: 0.5 }}
            >
              <button className="bg-blue-900 text-white py-3 px-6 rounded-full rounded-tl-lg">
                Find out more
              </button>
            </motion.div>
          </motion.div>
          <motion.div
            className="w-full md:w-1/2 mt-12 md:mt-0 z-20"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={a3}
              alt="Technology for Innovators"
            />
          </motion.div>
        </div>
      </section>
      <img className="-mt-48 z-10" src={CurveBtmLeft} />
      <section className="z-0 flex px-40 pt-40 flex-col bg-[#f5f5f5] ">
        <motion.h1
          className="text-5xl md:text-5xl font-extrabold text-pink-500"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: true, amount: 0.5 }}
        >
          Transformative Solutions
          <br />
          <span className="text-[#210d94]">for the Digital Age</span>
        </motion.h1>

        <div className="py-10 flex flex-row">
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={
                require("../../assets/images/icons/always connected.svg")
                  .default
              }
              width={60}
              alt="Always Connected"
            />
            <h3 className="text-2xl font-normal">Always Connected</h3>
            <p className="pt-1 text-sm">
              We simplify complex challenges, ensuring your business stays agile
              and ahead in a fast-paced digital world.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={
                require("../../assets/images/icons/seamless integration.svg")
                  .default
              }
              width={60}
              alt="Seamless Integration"
            />
            <h3 className="text-2xl font-normal">Seamless Integration</h3>
            <p className="pt-1 text-sm">
              Integrate our state-of-the-art digital solutions into your
              existing frameworks by streamlines operations, and accelerate
              growth without disruption.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 2 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={require("../../assets/images/icons/innovation.svg").default}
              width={60}
              alt="Real-Time Innovation"
            />
            <h3 className="text-2xl font-normal">Real-Time Innovation</h3>
            <p className="pt-1 text-sm">
              Instant Transformation leverage our real-time capabilities to
              drive operational excellence and data-driven decisions.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 3 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={
                require("../../assets/images/icons/global expertise.svg")
                  .default
              }
              width={60}
              alt="Global Digital Expertise"
            />
            <h3 className="text-2xl font-normal">Global Digital Expertise</h3>
            <p className="pt-1 text-sm">
              Benefit from international digital solutions designed to foster
              innovation and growth across diverse markets. We help you scale
              globally with ease.
            </p>
          </motion.div>
        </div>

        <div className="py-10 flex flex-row justify-center">
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={
                require("../../assets/images/icons/holistic digital.svg")
                  .default
              }
              width={60}
              alt="Holistic Digital Approach"
            />
            <h3 className="text-2xl font-normal">Holistic Digital Approach</h3>
            <p className="pt-1 text-sm">
              We combine ethical practices with a focus on sustainable digital
              growth to future-proof your business.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 2 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={
                require("../../assets/images/icons/Collabrative.svg").default
              }
              width={60}
              alt="Client-Centric Solutions"
            />
            <h3 className="text-2xl font-normal">Client-Centric Solutions</h3>
            <p className="pt-1 text-sm">
              Partner with us to create tailored digital strategies that meet
              your specific needs. We ensures high-quality results and
              accelerated digital transformation.
            </p>
          </motion.div>
          <motion.div
            className="w-1/4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 3 * 0.2 }}
            viewport={{ once: true, amount: 0.5 }}
          >
            <img
              src={
                require("../../assets/images/icons/Future solution.svg").default
              }
              width={60}
              alt="Future-Ready Solutions"
            />
            <h3 className="text-2xl font-normal">Future-Ready Solutions</h3>
            <p className="pt-1 text-sm">
              We equip your business with the tools needed to thrive in the
              evolving digital landscape.
            </p>
          </motion.div>
        </div>

        {/* Animated Buttons */}
        <motion.div
          className="flex flex-row justify-center mb-10"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
          viewport={{ once: true, amount: 0.5 }}
        >
          <button className="bg-[#210d94] text-white px-8 py-6 ml-2 rounded-full text-base rounded-br-lg font-extrabold">
            Know More
          </button>
        </motion.div>
      </section>
      <img src={CurveTopRight} />
      <section>
        <motion.div
          className="flex flex-col md:flex-row items-center overflow-x bg-white px-8 -mt-28 mb-40 overflow-x-hidden"
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          viewport={{ once: false }}
        >
          <motion.div
            className="w-full md:w-1/2 z-20"
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            <img
              src={g2}
              alt="Technology for Innovators"
            />
          </motion.div>
          <motion.div
            className="w-full md:w-1/2 text-center md:text-left p-24"
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            <h1 className="text-5xl md:text-5xl font-extrabold text-pink-500">
              Digital Solutions for
            </h1>
            <h1 className="text-5xl md:text-5xl font-extrabold text-blue-900 mt-2">
              Modern Enterprise
            </h1>
            <p className="text-gray-600 mt-4">
              Empower Your organization with our state-of-the-art digital
              solutions. We combine innovative technology with strategic
              insights to drive enterprise-wide efficiency and growth.
            </p>
            <hr
              className="my-5"
              style={{
                backgroundColor: "#2222221a",
                height: 2,
              }}
            />
            <div>
              <Slider {...settingstestimonials}>
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="relative text-[#181818] my-4">
                    <div className="relative">
                      <blockquote className="relative">
                        <p className="text-xl py-3">{testimonial.quote}</p>
                        <span className="absolute top-0 right-0 ">
                          <IconQuote size={65} color="#2222221a" stroke={3} />
                        </span>
                      </blockquote>
                    </div>
                    <p className="text-sm">{testimonial.text}</p>
                    <div>
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <span key={i} className="text-pink-500 text-lg">
                          ★
                        </span>
                      ))}
                    </div>
                    <p className="font-bold text-gray-800 text-sm">
                      {testimonial.author}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="mt-8">
              <button className="bg-blue-900 text-white py-3 px-6 rounded-full rounded-tl-lg">
                View all testimonials
              </button>
            </div>
          </motion.div>
        </motion.div>
      </section>
      <section className="bg-gray-200 py-8">
        <div className="container mx-auto">
          <Slider {...settingsclients}>
            {clients.map((item, index) => (
              <div key={index} className="p-4">
                <img
                  src={item.src}
                  alt={item.alt}
                  className="w-48 h-auto mx-auto"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="bg-[#210d94] text-white p-8 relative min-h-screen">
        <div className="mx-auto py-16">
          <div className="flex flex-wrap">
            {/* Animated Text and Table */}
            <motion.div
              className="w-full lg:w-2/3 flex flex-col p-20"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: false }}
            >
              <h1 className="text-5xl md:text-5xl text-pink-500">
                Compare Our Product
                <br />
                <span className="text-white font-extrabold">With others</span>
              </h1>
              <motion.div
                className="mt-4"
                initial={{ opacity: 0, scale: 0.95 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, type: "spring", stiffness: 120 }}
                viewport={{ once: false }}
              >
                <table className="w-full text-left">
                  <thead className="border-b-2 border-pink-500">
                    <tr>
                      <th className="p-2">Features</th>
                      <th className="p-2">Our Product</th>
                      <th className="p-2">Node+</th>
                      <th className="p-2">Analytica</th>
                    </tr>
                  </thead>
                  <tbody>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">OS Support</td>
                      <td className="p-2">iOS, Android, Windows</td>
                      <td className="p-2">iOS, Android</td>
                      <td className="p-2">iOS, Android</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">Support of services</td>
                      <td className="p-2">✔️</td>
                      <td className="p-2">✔️</td>
                      <td className="p-2">❌</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">Security & software updates</td>
                      <td className="p-2">✔️</td>
                      <td className="p-2">✔️</td>
                      <td className="p-2">✔️</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">Online backup</td>
                      <td className="p-2">✔️</td>
                      <td className="p-2">❌</td>
                      <td className="p-2">❌</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">CPU</td>
                      <td className="p-2">Quad Octa core</td>
                      <td className="p-2">Octa core</td>
                      <td className="p-2">Dual Octa core</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">GPU</td>
                      <td className="p-2">Quad core</td>
                      <td className="p-2">Dual core</td>
                      <td className="p-2">Single core</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">Internal storage</td>
                      <td className="p-2">500GB</td>
                      <td className="p-2">250GB</td>
                      <td className="p-2">128GB</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">External storage</td>
                      <td className="p-2">up to 1TB</td>
                      <td className="p-2">up to 256GB</td>
                      <td className="p-2">up to 500GB</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">Online storage</td>
                      <td className="p-2">1TB</td>
                      <td className="p-2">15GB</td>
                      <td className="p-2">15GB</td>
                    </motion.tr>
                    <motion.tr
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.8 }}
                      viewport={{ once: false }}
                    >
                      <td className="p-2">API</td>
                      <td className="p-2">✔️</td>
                      <td className="p-2">❌</td>
                      <td className="p-2">❌</td>
                    </motion.tr>
                  </tbody>
                </table>
              </motion.div>
            </motion.div>

            {/* Animated Image */}
            <motion.div
              className="w-full lg:w-1/3 flex items-center lg:justify-start"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: false }}
            >
              <img
                src={b1}
                alt="Features comparison"
                className="rounded-full"
              />
            </motion.div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <img
            src="https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/bgn-white-curve-bottom-right.png"
            alt="Designs Curves"
          />
        </div>
      </section>
      <section className="relative overflow-hidden bg-cover bg-no-repeat py-20 lg:py-32">
        <div className="absolute top-0 left-0 w-full">
          <img
            src="https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/bgn-blue-curve-top.png"
            alt="Top Section Coverage Image"
            className="w-full"
          />
        </div>

        <div className="relative z-10 max-w-6xl mx-auto px-4 lg:px-0">
          <motion.div
            className="grid grid-cols-1 lg:grid-cols-1 gap-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            <div className="flex flex-col justify-center">
              <h1 className="text-5xl md:text-5xl font-extrabold text-pink-500">
                Guiding with
                <br />
                <span className="text-[#210d94]">Vision and Innovation</span>
              </h1>
              <p className="mt-6">
                Our leadership team at Al Jazeera Technology Solutions blends
                strategic vision with deep industry expertise to drive
                innovation across healthcare, FinTech, and GRC sectors. By
                fostering a culture of collaboration and continuous improvement,
                they lead the way in delivering cutting-edge solutions that meet
                the evolving needs of our clients.
              </p>
            </div>
          </motion.div>

          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-16"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: false }}
          >
            {[
              {
                name: "Naif Hamed Omar Fadhil",
                imgSrc:
                  "https://grandgrp.co/wp-content/uploads/2024/02/20240218_102805-scaled-e1708924809842.jpg",
                description: "Managing Director & Chief Executive Officer",
              },
              {
                name: "Ramachandran MV",
                imgSrc: Ramachandran,
                description: "Group Chief Financial Officer",
              },
              {
                name: "Vishal Srinivasan",
                imgSrc: vishal,
                description: "Group Portfolio Strategist",
              },
              {
                name: "Karthik Gunasekar",
                imgSrc: karthick,
                description: "Chief Technology Officer",
              },
              {
                name: "Imthyaz Sheriff",
                imgSrc: imthyaz,
                description: "Chief Business Strategist",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                className="flex flex-col items-start text-center lg:text-left"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: false }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <div className="relative">
                  <a href="#" target="_self">
                    <img
                      loading="lazy"
                      decoding="async"
                      src={item.imgSrc}
                      alt={item.name}
                      className="w-full h-auto rounded object-cover"
                    />
                    <div className="absolute inset-0 flex items-end p-4">
                      <div className="text-right ml-auto">
                        <div
                          className="flex items-center justify-center w-10 h-10 rounded-full text-white"
                          style={{ backgroundColor: "#210d94" }}
                        >
                          <IconSearch />
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <h3 className="text-3xl text-[#210D94] font-bold mt-4">
                  {item.name}
                </h3>
                <p className="mt-2">{item.description}</p>
                <a
                  href="#"
                  target="_self"
                  className="mt-4 inline-block bg-pink-500 text-white py-2 px-4 rounded-br-lg rounded-full"
                >
                  Read more
                </a>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default Home;
