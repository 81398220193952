import React from "react";
import {
  IconMail,
  IconWorld,
  IconSend,
  IconPhoneCall,
  IconBrandX,
  IconBrandLinkedin,
  IconBrandFacebook,
  IconBrandYoutube,
} from "@tabler/icons-react";
import logo from "../assets/images/Logo.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const date = new Date();
  return (
    <footer className=" bg-[#210d94] lg:rounded-tr-[250px] rounded-none relative flex flex-col items-center justify-center font-Jakarta">
      <div className="w-full flex flex-col items-center justify-center py-20">
        <div className="lg:w-[85%] xl:w-[80%] md:w-full  xl:grid-cols-4 lg:grid-cols-4 grid  md:grid-cols-2 grid-cols-1 text-white py-5 gap-5 lg:px-0 xl:px-0 md:px-10 px-8 ">
          <div className="flex flex-col space-y-5 items-start justify-center relative">
            <img src={logo} alt="Logo" />
            <p className="text-[14px] text-justify">
              Seamlessly align your digital transformation strategies with
              innovative technology infrastructures. Empower your organization
              with cutting-edge solutions that drive efficiency and growth.
            </p>
            <ul className="flex flex-col space-y-2 text-[14px]">
              <li className="flex items-center gap-2">
                <IconPhoneCall size={25} />
                <a href="tel:+0123456789">+44 237 667</a>
              </li>
              <li className="flex items-center gap-2">
                <IconMail size={25} />
                <a href="mailto:example@mail.com">example@mail.com</a>
              </li>
              <li className="flex items-center gap-2">
                <IconWorld size={25} />
                <a href="mailto:example@mail.com">support@mail.com</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col space-y-5 mx-20 relative">
            <div className="">
              <span className="text-[25px] font-bold">Applications</span>
            </div>
            <ul className="flex flex-col space-y-6 text-[14px]">
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] after:hover:bg-red-600 py-2 hover:opacity-[0.5]"
                >
                  Our Products
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] py-2 after:hover:bg-red-600 hover:opacity-[0.5]"
                >
                  News & Updates
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] py-2 after:hover:bg-red-600 hover:opacity-[0.5]"
                >
                  Try for free
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] py-2 after:hover:bg-red-600 hover:opacity-[0.5]"
                >
                  Features
                </a>
              </li>
              <li>
                <a href="#" className="hover:opacity-[0.5]">
                  Pricelist
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col space-y-5 ml-10 relative">
            <div className="">
              <span className="text-[25px] font-bold">Al Jazeera</span>
            </div>
            <ul className="flex flex-col space-y-6 text-[14px]">
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] after:hover:bg-red-600 py-2 hover:opacity-[0.5]"
                >
                  About us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] py-2 after:hover:bg-red-600 hover:opacity-[0.5]"
                >
                  Contact us
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] py-2 after:hover:bg-red-600 hover:opacity-[0.5]"
                >
                  Locations
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="relative w-full after:content-[''] after:block after:absolute after:h-[0.5px] after:bottom-0 after:left-0 after:right-0 after:opacity-[0.5] z-10 after:bg-white after:w-[100%] py-2 after:hover:bg-red-600 hover:opacity-[0.5]"
                >
                  Jobs @ Al Jazeera
                </a>
              </li>
              <li>
                <a href="#" className="hover:opacity-[0.5]">
                  Blog
                </a>
              </li>
            </ul>
          </div>

          <div className="flex flex-col space-y-5">
            <div className="">
              <span className="text-[25px] font-bold">Connect</span>
            </div>

            <ul className="flex flex-row  space-x-5">
              <li className="text-[14px]">
                <a href="#">
                  <IconBrandFacebook
                    size={25}
                  />
                </a>
              </li>
              <li className="text-[14px]">
                <a href="#">
                  <IconBrandX
                    size={25}
                  />
                </a>
              </li>
              <li className="text-[14px]">
                <a href="#">
                  <IconBrandLinkedin
                    size={25}
                  />
                </a>
              </li>
              <li className="text-[14px]">
                <a href="#">
                  <IconBrandYoutube
                    size={25}
                  />
                </a>
              </li>
            </ul>
            <p className="text-[14px] text-justify">
              Keep up to date with the latest news and updates from Al Jazeera
              Technology Solutions. Subscribe with your email address to receive
              exclusive insights and developments on our digital transformation
              solutions.
            </p>

            <form>
              <div className="bg-white w-fit py-3 px-2 flex flex-row space-x-3 rounded-full text-black">
                <input
                  type="email"
                  placeholder="johndoe@example.com"
                  className="bg-transparent outline-none"
                />
                <button
                  type="submit"
                  className="bg-[#e639c8] p-2 rounded-b-full rounded-r-full"
                >
                  <IconSend color="white" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="relative bottom-0  w-full">
        <img
          src="https://codiqa.bold-themes.com/software/wp-content/uploads/sites/2/2019/08/bgn-footer-bottom.png"
          alt=""
          className="float-left "
        />
        <div className="absolute text-white text-[14px] w-full flex flex-col items-center h-full justify-center ">
          <div className="w-[80%] flex flex-row flex-wrap  justify-between py-2  lg:py-5 md:py-5 border-t border-gray-500">
            <p>
              Copyright {date.getFullYear()} - Al Jazeera. All rights reserved{" "}
            </p>
            <ul className="flex flex-row space-x-2 items-center justify-center">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/aboutus">About</NavLink>
              </li>
              <li>
                <NavLink to="/services">Service</NavLink>
              </li>
              <li>
                <NavLink to="/contactus">Contact</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
